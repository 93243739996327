import request from '../request'
 
//我的课程-查询
export function getCourse(params) {
  return request({
    url: `/study/course`,
    method: 'get',
    params
  })
}

//我的课程-视频
export function getVideo(params) {
  return request({
    url: `/study/course/detail`,
    method: 'get',
    params
  })
}

//我的课程-视频-保存视频进度
export function postStudyVideo(data) {
  return request({
    url: `/study/course/video/save`,
    method: 'post',
    data
  })
}

//我的课程-视频-保存学习进度
export function postStudyRecord(data) {
  return request({
    url: `/study/course/record/save`,
    method: 'post',
    data
  })
}