<template>
  <div class="position">
    
      <div class="position-model">
          <div class="position-model-img"><img src="@/assets/img/zw.png" alt=""></div>
            <div class="position-model-text">{{text}}</div>
      </div>
       
      
  </div>
</template>

<script>
export default {
  created() {
    //在data中定义的数据还没被绑定,所以不要在这里赋值
  },
  mounted() {
    //定义数据已经被绑定  可以在这里进行数据ajax绑定
  },
  props: {
    text:{
        
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.position{
    width: 100%;
    
 
}
.position-model{
    text-align: center;
    margin: auto;
    position: relative;
 
    padding-top:5% ;
    padding-bottom:5% ;
}
.position-model-img{
    margin: auto;
    width: 274px;  
}
img{
  width: 100%;
  height: 100%;
}
.position-model-text{
    text-align: center;
    font-size: 16px;
    color:#657489 ;
    margin-top:20px ;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 400;
}
</style>
